<template>
  <div class="">
    <div class="container my-2 d-flex flex-row justify-content-between">
      <img
        alt="ossix logo"
        src="../../assets/images/ossix-logo.png"
        width="200"
      />
      <div>
        <SignOutButton />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import SignOutButton from "./SignOutButton.vue";

export default {
  name: "Header",
  components: {
    SignOutButton,
  },
  methods: {
    isActive(value) {
      console.log(value);
      return this.active === value;
    },
  },
};
</script>

<style scoped></style>
