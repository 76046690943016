import { ref } from "vue";
import { createApp } from "vue";

import App from "./App.vue";
import { router } from "./router";
import { firebaseAuth } from "./config/index";
import initWebsocket from "./helpers/initWebsocket";

const isLoggedIn = ref(true);

const app = createApp(App);

firebaseAuth.onAuthStateChanged((user) => {
  if (user) {
    isLoggedIn.value = true;
    initWebsocket();
  } else {
    router.push("/login");
  }
});

app.use(router);
app.mount("#app");
