import * as events from "../../constants/events";
import jsonParse from "../../helpers/jsonParse";

const runSSHCommand = (action, state, setLogs) => {
  const socket = window.wsClient;
  const { currentApp } = state;
  if (socket?.readyState === WebSocket.OPEN) {
    state.runningSSHCommand = true;

    if (action === "deploy") {
      socket.send(
        JSON.stringify({
          type: events.DEPLOY_APP,
          body: { ...currentApp, action },
        }),
      );
    }
    if (action === "restart") {
      socket.send(
        JSON.stringify({
          type: events.RESTART_APP,
          body: { ...currentApp, action },
        }),
      );
    }
    if (action === "stop") {
      socket.send(
        JSON.stringify({
          type: events.STOP_APP,
          body: { ...currentApp, action },
        }),
      );
    }

    socket.addWsMessageHandlers((event) => {
      if (
        event.type === events.DEPLOYMENT_LOGS ||
        event.type === events.RESTART_APP_LOGS ||
        event.type === events.STOP_APP_LOGS
      ) {
        setLogs(jsonParse(event.body));
      }
      if (
        event.type === events.DEPLOYMENT_END ||
        event.type === events.RESTART_APP_END ||
        event.type === events.STOP_APP_END
      ) {
        state.runningSSHCommand = false;
        setLogs(jsonParse(event.body));
      }
    });
  } else {
    setTimeout(() => runSSHCommand(action, state, setLogs), 1000);
  }
};

export default runSSHCommand;
