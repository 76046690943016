<template>
  <Modal
    :show="show"
    :title="title"
    :onShow="onShow || scrollToTopOfLogs"
    :onHide="onHide"
    :className="'modal-xl'"
  >
    <template v-slot:body>
      <div class="rounded p-3 bg-dark text-white" id="logsContainer">
        <div v-if="!loading && action === 'deploy'" class="mb-4">
          <label for="branch-name" class="form-label text-white"
            >Branch to deploy</label
          >
          <div class="input-group flex-nowrap">
            <span class="input-group-text" id="addon-wrapping">
              <img src="../../assets/git-icon.svg" alt="git-icon" />
            </span>
            <input
              id="branch-name"
              type="text"
              class="form-control"
              placeholder="Branch name"
              aria-label="Branch name"
              aria-describedby="addon-wrapping"
              :value="branchToDeploy"
              @keyup="handleBranchToDeployChange"
            />
          </div>
        </div>
        <pre v-if="logs" id="logs">{{ logs }}</pre>
        <p v-if="!(loading || logs)">
          Do you want to {{ action || "" }}
          <b class="currentAppName">{{ appName }}?</b>
        </p>
        <div v-if="loading && !logs">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="form-check d-flex flex-row justify-content-start flex-fill">
        <input
          class="form-check-input"
          type="checkbox"
          :value="autoScroll"
          id="autoScroll"
          :checked="autoScroll"
          @click="toggleAutoScroll"
        />
        <label class="form-check-label" for="autoScroll">
          &nbsp;Auto scroll
        </label>
      </div>
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
        Close
      </button>
      <button
        type="button"
        :class="loading ? 'btn btn-success disabled' : 'btn btn-success'"
        @click="onClick"
      >
        {{ loading ? "Running..." : "Yes" }}
      </button>
    </template>
  </Modal>
</template>
<script>
import { reactive, onMounted, onUpdated } from "vue";
import Modal from "../common/Modal.vue";

const scrollToTopOfLogs = () => {
  const logsContainerEl = document.querySelector("#logsContainer");
  setTimeout(() => logsContainerEl?.scrollTo({ top: 0, left: 0 }), 300);
};

const scrollToEndOfLogs = () => {
  const logsContainerEl = document.querySelector("#logsContainer");
  logsContainerEl?.scrollTo({
    top: logsContainerEl?.scrollHeight,
    left: 0,
    behavior: "smooth",
  });
};

const toggleAutoScroll = () => {
  state.autoScroll = !state.autoScroll;
};

const state = reactive({
  autoScroll: true,
  logs: true,
  show: false,
});

export default {
  props: {
    onShow: Function,
    onHide: Function,
    show: Boolean,
    title: String,
    appName: String,
    branchToDeploy: String,
    handleBranchToDeployChange: Function,
    logs: String,
    action: String,
    onClick: Function,
    loading: Boolean,
  },
  data() {
    return state;
  },
  methods: {
    toggleAutoScroll,
    scrollToTopOfLogs,
  },
  components: {
    Modal,
  },
  watch: {
    logs(value, oldValue) {
      if (state.autoScroll && value !== oldValue) {
        scrollToEndOfLogs();
      }
    },
    autoScroll(value, oldValue) {
      if (value && value !== oldValue) {
        scrollToEndOfLogs();
      }
    },
  },
  setup(props) {
    onMounted(() => {
      state.logs = props.logs;
    });
    onUpdated(() => {
      state.logs = props.logs;
    });
  },
};
</script>
<style>
#logsContainer {
  height: 65vh !important;
  overflow-y: scroll;
}
</style>
