<template>
  <div>
    <Header />
    <div class="container pt-4">
      <table class="table table-hover table-striped">
        <thead>
          <tr>
            <th scope="col">App</th>
            <th scope="col">Production</th>
            <th scope="col">Staging</th>
          </tr>
        </thead>
        <tbody>
          <App
            v-for="app in apps"
            :app="app"
            :key="app.name"
            :deploy="(appData) => setCurrentApp(appData, 'deploy')"
            :restart="(appData) => setCurrentApp(appData, 'restart')"
            :stop="(appData) => setCurrentApp(appData, 'stop')"
          />
          <tr>
            <td colspan="3">
              <div v-if="loading" class="p-lg-5 d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ConfirmModal
        :show="show"
        :onHide="() => toggleModal(false)"
        :action="currentAction"
        :onClick="handleConfirm"
        :title="`${currentApp.app} - ${currentApp.env}`"
        :appName="currentApp.app"
        :branchToDeploy="branchToDeploy"
        :handleBranchToDeployChange="handleBranchToDeployChange"
        :logs="
          logs ||
          getLocalStorage(
            `${currentAction}-${currentApp.app}-${currentApp.env}-logs`,
          )
        "
        :loading="runningSSHCommand"
      />
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";

import Header from "../common/Header.vue";
import App from "./App.vue";
import ConfirmModal from "./ConfirmModal.vue";
import getApps from "./getApps";
import runSSHCommand from "./runSSHCommand";
import LocalStorage from "../../helpers/localStorage";

const state = reactive({
  loading: true,
  show: false,
  apps: [],
  currentAction: "",
  currentApp: {},
  logs: "",
  branchToDeploy: "develop",
  runningSSHCommand: false,
});

const toggleModal = (show) => {
  state.show = show ?? !state.show;
};

const handleBranchToDeployChange = (e) => {
  const appData = state.currentApp;
  const branch = e?.target?.value?.replace(/[ ]/g, "");

  if (appData?.command?.includes("--branch=") && branch) {
    appData.command = appData.command
      .split(" ")
      .map((e) => (e.includes("--branch=") ? `--branch=${branch}` : e))
      .join(" ");

    state.branchToDeploy = branch;
    state.currentApp = appData;
  }
};

const setCurrentApp = (appData, action) => {
  state.logs = "";
  state.runningSSHCommand = false;
  state.currentApp = appData;
  state.currentAction = action;

  toggleModal();

  appData?.command?.split(" ").forEach((e) => {
    if (e.includes("--branch")) {
      state.branchToDeploy = e.replace("--branch=", "");
    }
  });
};

const setLogs = (data) => {
  const { currentApp = {}, currentAction = "" } = state || {};
  if (data.app && data.env && data.logs) {
    LocalStorage.set(
      `${currentAction}-${data.app}-${data.env}-logs`,
      data.logs,
    );
  }
  if (currentApp.app === data.app && currentApp.env === data.env) {
    state.logs = data.logs;
  }
};
const handleConfirm = () => {
  const { currentAction, currentApp } = state;
  if (currentAction) {
    runSSHCommand(currentAction, state, setLogs);
    LocalStorage.set(
      `${currentAction}-${currentApp.app}-${currentApp.env}-logs`,
      "",
    );
  }
};

export default {
  name: "Apps",
  components: {
    Header,
    App,
    ConfirmModal,
  },
  data() {
    return state;
  },
  methods: {
    toggleModal,
    setCurrentApp,
    handleConfirm,
    getLocalStorage: LocalStorage.get,
    handleBranchToDeployChange,
  },
  setup() {
    onMounted(() => {
      getApps(state);
    });
  },
};
</script>
