<template>
  <b-button
    @click="googleSignIn"
    class="google-button shadow-lg mb-5 rounded btn btn-lg py-2 px-4"
  >
    <img
      alt="Google Logo"
      src="../../assets/images/google_logo.png"
      class="img-fluid"
    />
    <span class="ps-3">Sign In with Google</span>
  </b-button>
</template>

<script>
import {
  firebaseAuth,
  getAuth,
  deleteUser,
  firebaseGetProvider,
} from "../../config/index";

export default {
  name: "GoogleButton",
  components: {},

  methods: {
    googleSignIn() {
      let provider = firebaseGetProvider;
      firebaseAuth
        .signInWithPopup(provider)
        .then((result) => {
          // let token = result.credential.accessToken;
          let user = result.user;
          const admins = String(process.env.VUE_APP_ADMINS).split(",");

          if (admins.map((email) => email.trim()).includes(user.email)) {
            this.$router.push({ name: "Apps" });
          } else {
            const auth = getAuth();
            const user = auth.currentUser;

            deleteUser(user)
              .then(() => {
                alert(
                  "You're not allowed to access this application. Please check with the site admin if you believe this is a mistake."
                );
              })
              .catch((error) => {
                alert(error);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.google-button {
  background: #14274b;
  color: #fff;
}
.google-button img {
  width: 50px;
  height: 50px;
}
</style>
