<template>
  <div
    class="container d-flex flex-column align-items-center justify-content-center vh-100"
  >
    <img alt="OSSIX logo" src="../assets/images/ossix-logo.png" width="300" />
    <h3 class="py-5">OSSIX App Checker</h3>
    <GoogleButton />
  </div>
</template>

<script>
import GoogleButton from "./common/GoogleButton.vue";

export default {
  name: "Login",
  components: {
    GoogleButton,
  },
};
</script>
