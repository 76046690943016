<template>
  <tr>
    <td>{{ app.name }}</td>
    <td>
      <ul class="list-group">
        <li class="list-group-item">
          URL:
          <a :href="app.production_url" target="blank">
            {{ app.production_url }}
          </a>
        </li>
        <li class="list-group-item">
          Status:
          <span class="badge rounded-pill bg-success">UP</span>
        </li>
        <li class="list-group-item">
          <button
            v-if="app.deploy_production"
            type="button"
            class="mx-1 btn btn-sm btn-success"
            @click="
              () =>
                deploy({
                  ssh: app.ssh,
                  app: app.name,
                  command: app.deploy_production,
                  env: 'production',
                })
            "
          >
            Deploy <i class="bi bi-arrow-up-right-square"></i>
          </button>
          <button
            v-if="app.restart_production"
            type="button"
            class="mx-1 btn btn-sm btn-warning"
            @click="
              () =>
                restart({
                  ssh: app.ssh,
                  app: app.name,
                  command: app.restart_production,
                  env: 'production',
                })
            "
          >
            Restart <i class="bi bi-arrow-counterclockwise"></i>
          </button>
          <button
            v-if="app.stop_production"
            type="button"
            class="mx-1 btn btn-sm btn-danger"
            @click="
              () =>
                stop({
                  ssh: app.ssh,
                  app: app.name,
                  command: app.stop_production,
                  env: 'production',
                })
            "
          >
            Stop <i class="bi bi-x-circle"></i>
          </button>
        </li>
      </ul>
    </td>
    <td>
      <ul class="list-group">
        <li class="list-group-item">
          URL:
          <a :href="app.staging_url" target="blank">
            {{ app.staging_url }}
          </a>
        </li>
        <li class="list-group-item">
          Status:
          <span class="badge rounded-pill bg-success">UP</span>
        </li>
        <li class="list-group-item">
          <button
            v-if="app.deploy_staging"
            type="button"
            class="mx-1 btn btn-sm btn-success"
            @click="
              () =>
                deploy({
                  ssh: app.ssh,
                  app: app.name,
                  command: app.deploy_staging,
                  env: 'staging',
                })
            "
          >
            Deploy <i class="bi bi-arrow-up-right-square"></i>
          </button>
          <button
            v-if="app.restart_staging"
            type="button"
            class="mx-1 btn btn-sm btn-warning"
            @click="
              () =>
                restart({
                  ssh: app.ssh,
                  app: app.name,
                  command: app.restart_staging,
                  env: 'staging',
                })
            "
          >
            Restart <i class="bi bi-arrow-counterclockwise"></i>
          </button>
          <button
            v-if="app.stop_staging"
            type="button"
            class="mx-1 btn btn-sm btn-danger"
            @click="
              () =>
                stop({
                  ssh: app.ssh,
                  app: app.name,
                  command: app.stop_staging,
                  env: 'staging',
                })
            "
          >
            Stop <i class="bi bi-x-circle"></i>
          </button>
        </li>
      </ul>
    </td>
  </tr>
</template>
<script>
export default {
  props: {
    app: Object,
    deploy: Function,
    restart: Function,
    stop: Function,
  },
};
</script>
