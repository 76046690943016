<template>
  <div class="modal fade" id="modal" tabindex="-1" aria-hidden="true">
    <div :class="`modal-dialog ${className}`">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="title">{{ title }}</slot>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot name="body">{{ body }}</slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">{{ footer }}</slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, onUpdated, reactive } from "vue";

const state = reactive({
  show: false,
  onShow: () => undefined,
  onHide: () => undefined,
});

const addModalEventListeners = ({ onShow, onHide }) => {
  const modalEl = document.querySelector("#modal");
  if (window.bootstrap && modalEl) {
    if (typeof onShow === "function") {
      modalEl.addEventListener("show.bs.modal", onShow);
    }
    if (typeof onHide === "function") {
      modalEl.addEventListener("hidden.bs.modal", onHide);
    }
  }
};
const toggleModal = ({ show }) => {
  const modalEl = document.querySelector("#modal");

  if (window.bootstrap && modalEl) {
    const modal = new window.bootstrap.Modal(modalEl, {
      keyboard: false,
    });
    if (show) {
      modal.show();
    } else {
      modal.hide();
    }
  }
};

export default {
  props: {
    show: Boolean,
    onShow: Function,
    onHide: Function,
    title: String,
    body: String,
    footer: String,
    className: String,
  },
  data() {
    return state;
  },
  watch: {
    show(value, oldValue) {
      if (value !== oldValue) {
        toggleModal(state);
      }
    },
  },
  setup(props) {
    onMounted(() => {
      state.show = props.show;
      state.onShow = props.onShow;
      state.onHide = props.onHide;

      addModalEventListeners(props);
    });
    onUpdated(() => {
      state.show = props.show;
      state.onShow = props.onShow;
      state.onHide = props.onHide;
    });
  },
};
</script>
