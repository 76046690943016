export default function initWebsocket() {
  const wsURL = process.env.VUE_APP_WS_URL;
  let socket = new WebSocket(wsURL);

  socket.onopen = () => {
    console.log(`[websockets] Connected to ${wsURL}`);
    window.wsClient = socket;
  };

  socket.onclose = () => {
    console.log(`[websockets] Disconnected from ${wsURL}`);
    socket = null;
    window.wsClient = null;
    const websocketInterval = setInterval(() => {
      clearInterval(websocketInterval);
      initWebsocket();
    }, 1000);
  };

  const wsMessagesHandlers = [];
  socket.addWsMessageHandlers = (h) => wsMessagesHandlers.push(h);
  socket.onmessage = (e) => {
    let message = {};
    try {
      message = JSON.parse(e.data);
    } catch (error) {
      message = {};
    }
    wsMessagesHandlers.forEach((h) => {
      if (typeof h === "function") {
        h(message, e);
      }
    });
  };
  return socket;
}
