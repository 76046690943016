<template>
  <button
    @click="googleSignOut"
    class="signout-button btn d-flex align-items-center"
  >
    <i class="fs-3 bi bi-box-arrow-right"></i>
    <span class="ms-2">Logout</span>
  </button>
</template>

<script>
import { firebaseAuth } from "../../config/index";

export default {
  name: "SignOutButton",
  methods: {
    async googleSignOut() {
      firebaseAuth.signOut().then(() => {
        this.$router.push({ name: "Login" });
      });
    },
  },
};
</script>

<style lang="css">
.signout-button {
  background: #14274b;
  color: #fff;
}

.signout-button:hover {
  color: #ddd;
}
</style>
