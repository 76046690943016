export default class LocalStorage {
  static get(key, defaultValue) {
    try {
      return localStorage.getItem(key) || defaultValue;
    } catch (error) {
      return defaultValue || null;
    }
  }

  static set(key, value) {
    try {
      return localStorage.setItem(key, value);
    } catch (error) {
      return error;
    }
  }
}
