import { GET_APPS_REQUEST, GET_APPS_RESPONSE } from "../../constants/events";

const getApps = (state) => {
  const socket = window.wsClient;
  if (socket?.readyState === WebSocket.OPEN) {
    socket.send(JSON.stringify({ type: GET_APPS_REQUEST }));
    socket.addWsMessageHandlers((event) => {
      if (event.type === GET_APPS_RESPONSE) {
        state.apps = event.body;
        state.loading = false;
      }
    });
  } else {
    setTimeout(() => getApps(state), 1000);
  }
};

export default getApps;
