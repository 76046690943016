import { createWebHistory, createRouter } from "vue-router";

import LoginPage from "../components/Login.vue";
import Apps from "../components/Apps/index.vue";

const routes = [
  { path: "/login", name: "Login", component: LoginPage },
  {
    path: "/",
    name: "Apps",
    component: Apps,
  },
];

const router = createRouter({ history: createWebHistory(), routes: routes });

export { router };
