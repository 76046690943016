<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style lang="css">
.nav-link.active {
  background: #14274b !important;
  color: #fff !important;
}
.nav-link {
  color: #14274b !important;
}
</style>
