export const GET_APPS_REQUEST = "GET_APPS_REQUEST";
export const GET_APPS_RESPONSE = "GET_APPS_RESPONSE";

export const DEPLOY_APP = "DEPLOY_APP";
export const DEPLOYMENT_LOGS = "DEPLOYMENT_LOGS";
export const DEPLOYMENT_END = "DEPLOYMENT_END";

export const RESTART_APP = "RESTART_APP";
export const RESTART_APP_LOGS = "RESTART_APP_LOGS";
export const RESTART_APP_END = "RESTART_APP_END";

export const STOP_APP = "STOP_APP";
export const STOP_APP_LOGS = "STOP_APP_LOGS";
export const STOP_APP_END = "STOP_APP_END";
